import _styled from "styled-components";
import { LoginProvider, ProviderType } from 'components/LoginScreen/LoginScreen.types';
import { signIn } from 'next-auth/react';
import { ROOT_ROUTE } from '../../../../constants';
import { TextIconColor, Icon, Icons, Size } from '~/components/core';

// Min-width matches longest provider row, to ensure logos are aligned
const ProviderNameAndIcon = _styled.div({
  "display": "flex",
  "minWidth": "175px",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "0.75rem"
});
const StyledLoginButton = _styled.button({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "center",
  "borderRadius": "9999px",
  "borderWidth": "0.063rem",
  "borderColor": "#095A7C58",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(9 90 124 / var(--tw-border-opacity))"
  }
});
interface LoginButtonProps {
  provider: LoginProvider;
  setIsUsingSso: (isUsingSso: boolean) => void;
  returnUrl?: string | undefined;
}
const LoginButton = ({
  provider,
  returnUrl,
  setIsUsingSso
}: LoginButtonProps) => {
  const callbackUrl = returnUrl ?? ROOT_ROUTE;
  const handleSignIn = async () => {
    if (provider.id === ProviderType.Sso) {
      setIsUsingSso(true);
    } else {
      await signIn('workos', {
        callbackUrl
      }, {
        provider: provider.id
      });
    }
  };
  return <StyledLoginButton onClick={handleSignIn}>
      <ProviderNameAndIcon>
        <>
          {provider.id === ProviderType.GoogleOAuth && <Icons color={undefined} icon={Icon.GOOGLE} size={Size.LARGE} />}
          {provider.id === ProviderType.MicrosoftOAuth && <Icons color={undefined} icon={Icon.MICROSOFT} size={Size.LARGE} />}
          {provider.id === ProviderType.Sso && <Icons color={TextIconColor.BLACK} icon={Icon.LOCK} size={Size.LARGE} />}
        </>
        <span css={{
        "fontWeight": "468",
        "letterSpacing": "-0.05em",
        "--tw-text-opacity": "1",
        "color": "rgb(22 25 37 / var(--tw-text-opacity))"
      }}>
          Continue with {provider.name}
        </span>
      </ProviderNameAndIcon>
    </StyledLoginButton>;
};
export default LoginButton;