import _styled from "styled-components";
import { useEffect, useState } from 'react';
import { ERROR_PARAM, LOGIN_RETURN_URL_PARAM, MAGIC_LINK_ERROR_PARAM } from '../../constants';
import { useURLParams } from '../../hooks';
import LogoBgSVG from '../assets/logo-full.svg';
import { Background } from '../Background';
import { TextIconColor } from '../core';
import { Typography, TypographySize } from '../core/Typography';
import { LoginButton } from './components/LoginButton';
import { MagicLink } from './components/MagicLink';
import { Sso } from './components/Sso';
import LOGIN_PROVIDER_LIST from './LoginScreen.consts';
import { LoginError } from './LoginScreen.types';
const LoginScreenContainer = _styled.div({
  "display": "flex",
  "height": "100vh",
  "width": "100vw",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center"
});
const LoginScreenContent = _styled.div({
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "flexDirection": "column",
  "alignItems": "center",
  "borderRadius": "0.75rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
  "@media (min-width: 768px)": {
    "height": "502px",
    "width": "672px"
  }
});
const LoginScreenHeader = _styled.p({
  "marginTop": "2.25rem",
  "marginBottom": "1.5rem"
});
const LoginOptions = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.75rem",
  "padding": "3rem",
  "@media (min-width: 768px)": {
    "width": "440px",
    "padding": "0px"
  }
});
const OrDivider = _styled.div({
  "display": "flex",
  "height": "12px",
  "width": "100%",
  "justifyContent": "center",
  "borderBottomWidth": "0.063rem",
  "borderColor": "#095A7C58"
});
const OrDividerText = _styled.div({
  "height": "3rem",
  "width": "1.25rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
  "textAlign": "center",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "fontWeight": "468",
  "letterSpacing": "-0.05em",
  "--tw-text-opacity": "1",
  "color": "rgb(9 90 124 / var(--tw-text-opacity))"
});
const CopyrightText = _styled.span({
  "alignSelf": "center",
  "fontSize": "0.75rem",
  "lineHeight": "1rem",
  "fontWeight": "600",
  "letterSpacing": "-0.025em",
  "--tw-text-opacity": "1",
  "color": "rgb(22 25 37 / var(--tw-text-opacity))"
});
const LoginScreen = () => {
  const {
    returnUrl,
    magicLinkError,
    error
  } = useURLParams<{
    [ERROR_PARAM]?: string;
    [LOGIN_RETURN_URL_PARAM]?: string;
    [MAGIC_LINK_ERROR_PARAM]?: string;
  }>();
  const [isUsingSso, setIsUsingSso] = useState(false);
  useEffect(() => {
    if (error === LoginError.SSOError) {
      setIsUsingSso(true);
    }
  }, [error]);
  return <LoginScreenContainer>
      <Background />
      <LoginScreenContent>
        <LogoBgSVG css={{
        "marginTop": "58px"
      }} height={58} />
        <LoginScreenHeader>
          <Typography color={TextIconColor.GRAY} size={TypographySize.H3}>
            Sign into Kindo {isUsingSso ? 'with SSO' : ''}
          </Typography>
        </LoginScreenHeader>
        <LoginOptions>
          {!isUsingSso && <>
              {LOGIN_PROVIDER_LIST.map(provider => <LoginButton key={provider.id} provider={provider} returnUrl={returnUrl} setIsUsingSso={setIsUsingSso} />)}
              <OrDivider>
                <OrDividerText>or</OrDividerText>
              </OrDivider>
              <MagicLink magicLinkError={magicLinkError} />
            </>}
          {isUsingSso && <Sso error={error} returnUrl={returnUrl} setIsUsingSso={setIsUsingSso} />}
          <CopyrightText>
            {new Date().getFullYear()} Usable Machines, Inc.
          </CopyrightText>
        </LoginOptions>
      </LoginScreenContent>
    </LoginScreenContainer>;
};
export default LoginScreen;