import { LoginProvider, ProviderType } from './LoginScreen.types';
const LOGIN_PROVIDER_LIST: LoginProvider[] = [{
  id: ProviderType.Sso,
  name: 'SSO'
}, {
  id: ProviderType.GoogleOAuth,
  name: 'Google'
}, {
  id: ProviderType.MicrosoftOAuth,
  name: 'Microsoft'
}];
export default LOGIN_PROVIDER_LIST;