export type LoginProvider = {
  id: ProviderType;
  name: string;
};
export enum ProviderType {
  GoogleOAuth = 'GoogleOAuth',
  MicrosoftOAuth = 'MicrosoftOAuth',
  Sso = 'Sso',
}
export enum LoginError {
  SSOError = 'SSOError',
}